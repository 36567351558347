.product-card-hover {
    &:hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border: 1px solid #f8aa7d;
        box-sizing: border-box;
        box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.14);
        border-radius: 8px;

        z-index: 1;

        .active {
            display: block !important;
        }

        .product-bookmark {
            right: 15px !important;
        }

        .disable {
            display: none;
        }

        .product-card-img {
            img {
                padding: 0px !important;
                transition: 0.2s ease-in-out;
            }
        }

        .add-to-cart {
            display: none !important;
        }
    }
}

.product-card-container {
    border: 1px solid #f7f7f7;
    box-sizing: border-box;
    background-color: $white;
    transition: 0.3s ease-in-out;
    height: auto;
    overflow: hidden;

    .disable {
        display: block;
    }

    .active {
        display: block;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .product-card-inner {
        height: 100%;

        .product-card-header {
            position: relative;
            overflow: hidden;

            .offer {
                @include font($farm-font-montserrat, 600, 10px);
                position: absolute;
                top: 12px;
                left: 12px;
                background-color: #91cd77;
                border-radius: 14px;
                color: #fcfcfc;
                padding: 0.25rem 0.875rem;

                @include media-breakpoint-up(lg) {
                    border-radius: 16px;
                    font-size: 12px;
                    top: 15px;
                }
            }

            .product-card-img {
                width: 100%;
                height: 125px;
                img {
                    padding: 12px 12px 0px;
                    display: block;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                @include media-breakpoint-up(lg) {
                    height: 210px;

                    img {
                        padding: 24px 16px 0px;
                    }
                }
            }
            .product-bookmark {
                position: absolute;
                top: 12px;
                right: 12px;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.12);
                background-color: #ffffff;
                transition: 0.3s ease-in-out;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 16px;
                    width: 16px;
                }

                @include media-breakpoint-up(lg) {
                    top: 15px;
                    right: -50px;
                    width: 40px;
                    height: 40px;

                    svg {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
        .product-card-body {
            padding: 12px 8px;

            .product-category {
                @include font($farm-font-montserrat, 500, 8px);
                color: #878b87;
                text-transform: uppercase;
                display: block;
            }
            .product-title {
                @include font($farm-font-inter, 500, 16px);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
                display: inline-block;
            }
            .product-code-section {
                display: none;

                @include font($farm-font-montserrat, 400, 14px);
                color: $gray-4;

                .product-code {
                    @include font($farm-font-roboto, 400, 14px);
                    color: $gray-3;
                }
            }

            .product-card-footer {
                display: inline-block;
                width: 100%;
                .product-price-section {
                    .product-old-price {
                        position: relative;
                        @include font($farm-font-inter, 500, 12px);
                        color: $gray-4;
                        padding-left: 2px;
                        padding-right: 2px;
                        text-decoration: line-through;
                        text-decoration-color: $gray-4;
                        text-decoration-thickness: 2px;

                        @include media-breakpoint-up(lg) {
                            font-size: 16px;
                        }
                    }
                    .set-height {
                        height: 20px;
                    }
                    .product-new-price {
                        @include font($farm-font-montserrat, 500, 12px);
                        color: #3e423f;
                        margin: 0px;
                        span {
                            @include font($farm-font-inter, 500, 16px);
                            color: $secondary-color;
                            margin-right: 5px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 16px;
                            font-weight: 600;

                            span {
                                font-size: 20px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 24px 16px;

                .product-category {
                    font-size: 10px;
                    font-weight: 600;
                }

                .product-title {
                    font-size: 16px;
                }

                .product-code-section {
                    display: block;
                }
            }
        }
    }
}

.p-card-list {
    border-radius: 8px;

    .p-card-img {
        max-height: 120px;
        height: 100%;
        width: 160px;
        min-width: 160px;
        object-fit: contain;
    }

    .p-card-content {
        border-right: 1px dashed $light-gray-1;
    }
}

.wishlist-animate {
    animation-name: wishlist-ani;
    animation-duration: 1000ms;
}

@keyframes wishlist-ani {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.nostock-pill {
    @include font($farm-font-montserrat, 600, 10px);
    position: absolute;
    top: 14px;
    left: 14px;
    background-color: $red;
    border-radius: 14px;
    color: #fcfcfc;
    padding: 0.25rem 0.875rem;

    @include media-breakpoint-up(lg) {
        border-radius: 16px;
        font-size: 12px;
        top: 15px;
    }
}

.product-card-meat {
    .product-new-price {
        span {
            color: $meat-color-dark !important;
        }
    }
}

.product-card-meat-hover {
    &:hover {
        border: 1px solid $meat-color-light !important;
    }
}
