.btns {
  font-family: $farm-font-montserrat;
  font-weight: 600;
  border-radius: 40px;
  line-height: 0;
  cursor: pointer;

  transition: transform 0.3s ease;
  // box-sizing: border-box;
}

// btns colors and styles

.btn_primary--solid {
  background-color: $secondary-color;
  color: #f2f2f2;
  border: none;
  &:hover {
    box-shadow: 0px 10px 22px rgba(232, 94, 13, 0.25);
  }
}
.btn_primary--outline {
  background-color: $white;
  color: $secondary-color;
  border: 2px solid $secondary-color;
}

.btn--small {
  font-size: 14px;
  padding: 10px 28px;
}
.btn--medium {
  font-size: 16px;
  padding: 25px 45px;
}
.btn--large {
  font-size: 18px;
  padding: 22px 33px;
}
.ant-btn-primary {
  border-color: #f47b34;
  background: #f47b34;
  border-radius: 20px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #dc5b0f;
  background: #dc5b0f;
}
