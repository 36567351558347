.promo-container-wrapper {
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
        padding-top: 62px;
    }

    .app-promo-card {
        background-color: $light-gray-3;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        border-radius: 8px;
        padding: 16px;
        height: 100%;

        @include media-breakpoint-up(lg) {
            min-height: 250px;
            padding: 50px 32px 43px 32px;
        }

        @include media-breakpoint-up(xl) {
            min-height: 300px;
        }

        .app-promo-title {
            font-size: 24px;

            @include media-breakpoint-up(md) {
                font-size: 28px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 36px;
            }
        }

        .app-promo-info {
            font-size: 12px;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
        }
    }

    .promo-header {
        .promo-title {
            @include font($farm-font-inter, 600, 32px);
            color: #333333;
        }
    }
    .promo-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .promo-card {
            position: relative;
            height: 300px;
            padding: 40px 30px;
            border-radius: 8px;
            background-color: $light-gray-3;
            overflow: hidden;

            .promo-sub-title {
                @include font($farm-font-montserrat, 700, 12px);
                color: #333333;
            }

            h2 {
                @include font($farm-font-inter, 600, 36px);
                color: #333333;
            }

            p {
                @include font($farm-font-inter, 600, 14px);
                color: #333333;
            }

            a {
                margin-top: 60px;
                display: block;
                text-decoration: none;
                @include font($farm-font-roboto, 500, 16px);
                color: #f6f6f6;
                border-radius: 26px;
                background-color: $fg-orange;
                width: 169.66px;
                height: 48px;
                line-height: 48px;
                text-align: center;
            }

            img {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        .promo-card-1 {
            margin-right: 20px;
        }
    }

    .promo-banner-section {
        margin-top: 20px;
        border-radius: 8px;
        background-image: url('../../../images/promo-banner.png');
        min-height: 214px;
        display: flex;
        align-items: center;
        justify-self: center;

        .promo-banner-section-inner {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {
                @include font($farm-font-roboto, 700, 12px);
                color: $light-gray-3;
            }
            h2 {
                @include font($farm-font-roboto, 500, 36px);
                color: $light-gray-3;
                padding-top: 10px;
                padding-bottom: 15px;
            }

            .promo-code {
                cursor: pointer;
                padding: 10px 20px;
                border: 1px solid rgba(255, 255, 255, 0.81);
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 8px;
                text-align: center;
                line-height: 25px;
                @include font($farm-font-roboto, 700, 16px);
                color: $light-gray-3;
            }
        }
    }
}
