@import './variables';
@import '~bootstrap/scss/bootstrap.scss';
@import '~antd/dist/antd.css';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

@import './base';

@import './components/main';
@import './pages/main';
@import './components/Login/login';
@import './components/Register/register';
@import './components/Register/otp';
@import './components/Register/recovery';
@import './components/Register/checkmail';

@import './components/home/product';
@import './components/home/category_offer';
@import './components/home/meat_market';
@import './components/home/promo';
@import './components/home/customer';
@import './components/home/newsLetter';
@import './components/home/dariyproduct';
@import './components/home/sidedrawer';

@import './components/product_detail/product_detail';
@import './components/product_detail/product_brief';
@import './components/product_detail/product_bought';
@import './components/button/button';

@import './components/Card/card';
@import './components/Card/card';

@import './components/my_panel/mypanel';
