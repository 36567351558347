.ant-collapse-item {
    .ant-collapse-header {
        color: $dark !important;
    }
}

.ant-collapse-item-active {
    .ant-collapse-header {
        font-weight: 600;
    }
}
