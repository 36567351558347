.cart-table-container {
    @include media-breakpoint-up(md) {
        min-height: 470px;
    }

    .cart-table {
        thead,
        tr {
            border-bottom: 1px solid $border;
        }
    }
}

.order-summary-container {
    min-height: 600px;

    .apply-btn {
        &:hover {
            text-decoration: underline;
        }
    }
}

.redeem-input {
    border: 1px solid $light-gray;
    border-radius: 4px;

    .ant-input {
        color: $gray;
        font-family: $farm-font-montserrat;
        font-size: 0.875rem;
        font-weight: 500;
    }
}

.redeem-btn {
    background: transparent;

    border: 2px solid $secondary-color !important;
    span {
        color: $secondary-color;
    }
}

.promo-container {
    background-color: rgba(244, 123, 52, 0.15);

    .promo-input {
        padding: 10px 11px;
        border: 1px solid $light-gray;
        color: $gray;
        font-family: $farm-font-montserrat;
        font-size: 0.875rem;
        font-weight: 400;
    }
}
