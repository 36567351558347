.product-detail-inner {
    border-radius: 8px;
    position: relative;

    @include media-breakpoint-up(lg) {
        background-color: $white;
        padding: 15px;
    }

    // antd tabs
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #333333;
        text-shadow: 0 0 0.25px currentColor;
        font-family: $farm-font-inter !important;
        font-weight: 600 !important;
        font-size: 16px !important;

        @include media-breakpoint-up(lg) {
            font-size: 20px !important;
        }
    }

    .ant-tabs-tab {
        font-family: $farm-font-inter !important;
        font-weight: 400 !important;
        color: #878b87 !important;
        font-size: 16px !important;
        padding: 8px 0 !important;

        @include media-breakpoint-up(lg) {
            font-size: 20px !important;
            padding: 16px 0 !important;
        }
    }

    .ant-tabs-tab:hover {
        color: $secondary-color;
    }
    .ant-tabs-ink-bar {
        position: absolute;
        background: $secondary-color;
        pointer-events: none;
    }
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
        color: #333333;
    }

    .product-description-mask {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        background: linear-gradient(
            to top,
            $light-gray-2,
            rgba(255, 255, 255, 0.3) 100%
        );
    }

    .product-description {
        @include media-breakpoint-up(lg) {
            min-height: 240px;
        }

        ul {
            margin-left: 16px !important;
        }

        ul,
        li {
            display: list-item !important;
            list-style: disc !important;
        }

        p {
            font-family: $farm-font-montserrat !important;
            font-weight: 400;
            size: 14px;
        }
    }

    .product-tags {
        margin-top: 18px;
        bottom: 0;

        h2 {
            font-family: $farm-font-inter;
            font-weight: 500;
            font-size: 16px;
            color: #1c1e1c;
        }
        ul {
            &::after {
                clear: both;
                display: block;
                content: '';
            }
            li {
                float: left;
                margin-right: 20px;
                padding: 8px 26px;
                background-color: #f6f6f6;
                font-family: $farm-font-roboto;
                font-weight: 400;
                font-size: 14px;
                border-radius: 26px;
                color: #828282;
                cursor: pointer;
            }
        }
    }
    .reviews-wrapper {
        h3 {
            font-family: $farm-font-inter;
            font-weight: 500;
            font-size: 16px;
        }
        &-inner {
            padding-top: 20px;
            padding-bottom: 10px;
            .review-section {
                .review {
                    display: flex;
                    margin-bottom: 10px;
                    .reviewer-img {
                        img {
                            width: 56px;
                            height: 56px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                    .reviewer-body {
                        margin-left: 10px;
                        h2 {
                            font-family: $farm-font-inter;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 0.2;
                            margin-top: 15px;
                        }
                        .review-title {
                            display: flex;
                            align-items: flex-end;
                            span {
                                font-family: $farm-font-montserrat;
                                font-weight: 400;
                                font-size: 14px;
                                margin-right: 10px;
                                color: #878b87;
                            }
                            ul {
                                display: inline-block;
                                li {
                                    float: left;
                                }
                            }
                        }
                        p {
                            margin-top: 15px;
                            font-family: $farm-font-montserrat;
                            font-weight: 400;
                            font-size: 14px;
                            color: #3e423f;
                        }
                    }
                }
            }
            .review-form-section {
                margin-bottom: 40px;
                .review-form-header {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: baseline;
                    justify-content: space-between;
                    h2 {
                        font-family: $farm-font-inter;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    p {
                        font-family: $farm-font-montserrat;
                        font-weight: 400;
                        font-size: 12px;
                        color: #828282;
                        margin-right: 50px;
                    }
                }
                hr {
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                form {
                    .form-element {
                        padding: 10px 0;
                    }
                    .rate-label {
                        font-family: $farm-font-montserrat;
                        font-size: 14px;
                        font-weight: 400;
                    }
                    .rating {
                        margin-top: 15px;
                        margin-bottom: 30px;
                        display: flex;
                        align-items: center;
                        div:first-child {
                            padding-left: 0;

                            @include media-breakpoint-up(sm) {
                                padding-right: 20px;
                            }
                        }
                        div:last-child() {
                            border: none;

                            @include media-breakpoint-up(sm) {
                                padding-right: 20px;
                            }
                        }

                        .rate {
                            border-right: 1px solid #e0e0e0;
                            padding-left: 6px;
                            padding-right: 6px;

                            @include media-breakpoint-up(sm) {
                                padding-left: 20px;
                                padding-right: 20px;
                            }

                            ul {
                                margin: 0;
                                padding: 0;
                                margin: 0 -3px !important;

                                li {
                                    cursor: pointer;
                                    float: left;
                                    padding: 0 3px;
                                }
                            }
                        }
                    }

                    label {
                        font-family: $farm-font-montserrat;
                        font-size: 14px;
                        font-weight: 400;
                        color: #878b87;
                        display: block;
                        padding-bottom: 11px;
                    }
                    input[type='text'],
                    textarea {
                        width: 100%;
                        border: 1px solid #e8e8e8;
                        border-radius: 8px;
                        background-color: #f7f7f7;
                        padding: 12px 16px;
                        font-family: $farm-font-montserrat;
                        font-size: 14px;

                        &::-webkit-input-placeholder {
                            font-family: $farm-font-montserrat;
                            font-weight: 400;
                            font-size: 16px;
                        }
                    }
                    textarea {
                        height: 173px !important;
                    }
                }
            }
        }
    }
}

.product-detail-inner-meat {
    .ant-tabs-ink-bar {
        background: $meat-color-dark;
    }
}

// antd tabs
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333;
    text-shadow: 0 0 0.25px currentColor;
    font-family: $farm-font-inter !important;
    font-weight: 600 !important;
    font-size: 20px !important;
}

.ant-tabs-tab {
    font-family: $farm-font-inter !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    color: #878b87 !important;
}

.ant-tabs-tab:hover {
    color: $secondary-color;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
    color: #333333;
}

.product-header-link {
    border-bottom: 2px solid $secondary-color;
    padding: 0 4px 4px 4px;
    font-family: $farm-font-inter;
    font-weight: 500;
    font-size: 12px;
    color: $secondary-color;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
        padding-bottom: 8px;
    }
}
