.recovery-form-section {
    @include media-breakpoint-up(lg) {
        padding-top: 66px;

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
    h2 {
        @include font($farm-font-inter, 600, 20px);
    }
    p {
        @include font($farm-font-montserrat, 400, 14px);
        color: #828282;
        margin-bottom: 0;
    }
    form {
        .recover-email {
            margin-bottom: 33px;
        }
    }
    .hor-line {
        margin-top: 24px;

        @include media-breakpoint-up(lg) {
            margin-top: 57px;
        }
    }
    .recover-bottom {
        margin-top: 24px;
    }
}
