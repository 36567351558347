.app-hero {
    max-width: 1440px;
    margin: 0 auto;

    .hero-banner {
        aspect-ratio: 75/34;
        max-height: 512px;
        min-width: 100%;
        min-height: 170px;

        @include media-breakpoint-up(sm) {
            aspect-ratio: 18/7;
        }

        .hero-bg {
            background-repeat: no-repeat !important;
            background-size: cover !important;
            padding-left: 50px;
            padding-right: 50px;

            @include media-breakpoint-up(lg) {
                padding-left: 126px;
                padding-right: 126px;
            }

            .hero-title {
                font-size: 20px;
                margin-bottom: 12px;

                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 2.5rem;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 3.5rem;
                }
            }

            .hero-subtitle {
                font-size: 0.875rem;
                display: none;

                margin-bottom: 24px;

                @include media-breakpoint-up(lg) {
                    display: block;
                    margin-bottom: 30px;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 1rem;
                }
            }

            .veg-img {
                position: absolute;
                bottom: 0;
                right: 10%;
                width: 30%;
            }
        }
    }

    .ant-carousel {
        .slick-next,
        .slick-prev {
            height: 24px;
            width: 24px;
            color: $secondary-color;
            z-index: 10;

            @include media-breakpoint-up(lg) {
                height: 40px;
                width: 40px;
            }

            &::before {
                display: none;
            }
        }

        .slick-next {
            right: 10px;

            @include media-breakpoint-up(lg) {
                right: 35px;
            }
        }
        .slick-prev {
            left: 10px;

            @include media-breakpoint-up(lg) {
                left: 35px;
            }
        }

        .hero-dot {
            bottom: 10px;

            @include media-breakpoint-up(md) {
                bottom: 25px;
            }

            @include media-breakpoint-up(lg) {
                bottom: 50px;
            }

            .slick-active {
                border-radius: 8px;
                width: 44px;

                button {
                    background-color: $secondary-color;
                }
            }

            li {
                height: 12px;
                width: 12px;
                border-radius: 50%;
                overflow: hidden;

                button {
                    opacity: 1;
                    background-color: $gray-4;

                    height: 100%;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.hero-outer-container {
    max-width: 1440px;
    margin: 0 auto;
    background-size: cover;
    aspect-ratio: 1440/556;

    .hero-container {
        min-height: 280px;
        height: 100%;
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(lg) {
            padding-left: 126px;
            padding-right: 126px;
        }

        .hero-meat-heading {
            color: #ffffff;
            font-size: 36px;
            font-weight: 700;
            line-height: 2.5rem;
            padding-bottom: 10px;

            @include media-breakpoint-up(lg) {
                font-size: 56px;
                line-height: 4rem;
            }
        }
        .hero-subheading {
            color: #1c1e1c;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
        }
        .hero-meat-subheading {
            color: #ffffff;
            .hero-subheading {
                color: #1c1e1c;
                font-size: 14px;
                font-weight: 400;

                @include media-breakpoint-up(lg) {
                    font-size: 16px;
                }
            }
            .image-container {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .dot-container {
                position: absolute;
                bottom: 0;
                margin-bottom: 1rem;
                width: 80px;
                justify-content: space-between;
                .dot-circle {
                    background-color: $gray-4;
                    height: 12px;
                    width: 12px;
                    border-radius: 6px;
                }
                .dot-circle-selected {
                    background-color: #f47b34;
                    width: 38px;
                    height: 12px;
                    border-radius: 8px;
                }
            }
            .hero-offer {
                margin-top: -66px;
                margin-left: 160px;

                @include media-breakpoint-up(lg) {
                    margin-top: -100px;
                    margin-left: 190px;
                }

                span:nth-child(1) {
                    color: #e97856;
                    font-weight: bold;
                    font-size: 56px;

                    @include media-breakpoint-up(lg) {
                        font-size: 107.428px;
                        line-height: 117px;
                    }
                }
                span:nth-child(2) {
                    color: #e97856;
                    font-weight: 600;
                    font-size: 28px;
                    text-transform: uppercase;
                    @include media-breakpoint-up(lg) {
                        font-size: 40px;
                        line-height: 52px;
                    }
                }
            }
        }
    }
}

.contact-banner-container {
    height: 360px;
    background-color: #f6f6f6;

    .app-container {
        padding-bottom: 160px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 200px;
        }
    }

    .image-container {
        img {
            opacity: 0.3;
        }
    }
    .hero-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        color: $dark;
        font-family: $farm-font-inter;

        @include media-breakpoint-up(lg) {
            font-size: 48px;
        }
    }
    .hero-subheading {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        max-width: 617px;
        color: $gray;
        font-family: $farm-font-montserrat;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }

    @include media-breakpoint-up(lg) {
        height: 441px;
    }
}
.contact-form-container {
    margin: 0 auto 152px;

    .contact-form {
        background-color: #fff;
        padding: 24px 16px !important;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 8px 8px 0 0;

        @include media-breakpoint-up(md) {
            border-radius: 8px 0 0 8px;
        }

        @include media-breakpoint-up(lg) {
            padding: 30px !important;
        }

        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 20px;
        }
        label {
            font-size: 14px;
            line-height: 18px;
            color: #878b87 !important;
        }
        .ant-form-item {
            margin-bottom: 18px;
        }
        .ant-form-item .ant-mentions,
        .ant-form-item textarea.ant-input {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            padding: 11px;
        }
    }
    .contact-info-container {
        background-image: url('../../../images/contactUs/contact-info-bg.png');
        background-color: #f47b34;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 0 0 8px 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            border-radius: 0 8px 8px 0;
        }

        h4 {
            text-align: center;
            padding-top: 30px;
            color: #fff;
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
        }
        .contact-info-detail {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            max-width: 290px;
        }
    }
    .contact-form-footer {
        display: inline-block;
        font-size: 12px;
        line-height: 16px;
        color: #878b87;
        font-family: $farm-font-montserrat;

        a {
            font-size: 12px;
            line-height: 16px;
            color: $secondary-color;
            font-family: $farm-font-montserrat;
            font-weight: 600;
        }
    }
    .contact-info-social {
        margin-bottom: 72px;
        span {
            a {
                margin-right: 20px;
            }
        }
    }
    .ant-input:hover,
    .ant-input:focus,
    .ant-input-focused {
        border-color: #f8aa7d;
        box-shadow: rgba(244, 123, 52, 0.2) 0 0 0 2px;
    }
}
