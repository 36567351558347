.checkbox-field {
    margin-bottom: 28px;

    .checkbox-text {
        display: inline;
        margin-left: 8px;
        @include font($farm-font-montserrat, 400, 14px);
        color: #878b87;

        span {
            @include font($farm-font-montserrat, 600, 14px);
            color: #f47b34;
            cursor: pointer;
        }

        @media only screen and (min-width: 992px) {
            font-size: 16px;

            span {
                font-size: 14px;
            }
        }
    }
}

.horizontal-l {
    margin-top: 24px;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 38px;
    }
}
