.step-icon-current-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid $secondary-color;
}

.step-icon {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: $white;
    }
}

.step-icon-pending {
    border: 1px solid $secondary-color-light;

    svg {
        fill: $secondary-color-light;
    }
}

.step-icon-current {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
}

.step-icon-done {
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
}

.app-radio-group {
    width: 100%;

    .ant-space {
        width: 100%;
    }
}

.app-radio {
    span {
        &:not(.ant-radio) {
            flex-grow: 1;
            text-align: left;
        }
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .ant-radio {
        .ant-radio-inner {
            border: 2px solid $secondary-color;
            height: 24px;
            width: 24px;

            &::after {
                height: 14px;
                width: 14px;
                background-color: $secondary-color;
                transform: scale(1);
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .ant-radio-checked {
        &::after {
            border: 2px solid $secondary-color;
        }
    }
}

.app-radio-btn {
    height: 32px !important;
    font-family: $farm-font-montserrat;
    font-weight: 400;
    color: $gray-4;
    font-size: 14px !important;
    line-height: 34px !important;
    background-color: #f6f6f6;
    border-radius: 4px;
    border: none;
    border-left: none !important;

    &:hover {
        color: $secondary-color;
    }
}

.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: $secondary-color;
    border-color: $secondary-color;

    &:hover {
        color: #fff;
        background: $secondary-color-light;
        border-color: $secondary-color-light;
    }
}

.checkout-opt-input {
    div {
        margin-right: 8px;
        input {
            width: 44px !important;
            height: 44px;
            border-radius: 4px;
            background-color: $light-gray-2;
            border: 2px solid $light-gray;
            color: $dark;
        }
    }
}

.cod-detail-container {
    background-color: rgba(244, 123, 52, 0.1);
}

.checkout-new-container {
    border: 1px solid $light-gray;
}

.checkout-min-container {
    border: 1px solid $meat-color-dark;

    .min-order-close {
        position: absolute;
        top: -10px;
        right: -10px;
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
