.pagination-container {
    .pagination-inner {
        .pagination-arrow-container {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            width: 32px;
            min-width: 32px;
            border-radius: 50%;
            border: 2px solid $secondary-color;

            svg {
                stroke: $secondary-color;
            }

            &:hover {
                background-color: $secondary-color;
                border-color: #fcfcfc;
                box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.2);

                svg {
                    stroke: #fcfcfc;
                }
            }
        }

        .arrow-disabled {
            cursor: default;
            border: 2px solid $secondary-color-light;

            svg {
                stroke: $secondary-color-light;
            }

            &:hover {
                background-color: transparent;
                border-color: $secondary-color-light;
                box-shadow: none;

                svg {
                    stroke: $secondary-color-light;
                }
            }
        }

        .arrow-prev {
            margin-right: 20px;
        }

        .arrow-next {
            margin-left: 20px;
        }

        .page-number {
            cursor: pointer;
            width: 40px;
            background-color: $light-gray;

            span {
                color: $gray-4;
            }
        }

        .page-active {
            background-color: $secondary-color;

            span {
                color: #fcfcfc;
            }
        }
    }
}

.pagination-meat {
    .pagination-inner {
        .pagination-arrow-container {
            border: 2px solid $meat-color-dark;

            svg {
                stroke: $meat-color-dark;
            }

            &:hover {
                background-color: $meat-color-dark;
            }
        }

        .arrow-disabled {
            border: 2px solid $meat-color-light;

            svg {
                stroke: $meat-color-light;
            }

            &:hover {
                background-color: transparent;
                border-color: $meat-color-light;

                svg {
                    stroke: $meat-color-light;
                }
            }
        }

        .page-active {
            background-color: $meat-color-dark;
        }
    }
}
