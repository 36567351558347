.cart-page {
    .ant-drawer-content {
        overflow: inherit;
        background-color: transparent;
        padding-top: 56px;

        @include media-breakpoint-up(sm) {
            padding-top: 0;
        }
    }

    .sidedrawer-cart {
        overflow: hidden;
        height: 100%;
        border-radius: 8px 8px 0px 0px;
        box-shadow: 0px 12px 45px rgba(0, 0, 0, 0.1);
        background-color: $background-2;

        .close_search {
            position: absolute;
            right: 28px;
            top: 18px;
            width: 30px;
            height: 30px;
            background: $background-2;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(sm) {
                left: -40px;
                top: 20px;
            }
        }

        @include media-breakpoint-up(sm) {
            border-radius: 8px 0px 0px 8px;
        }
    }

    .cart-header {
        background-color: #1c1e1c;
        h2 {
            @include font($farm-font-inter, 600, 16px);
            color: #ffffff;
            padding-top: 23px;
            padding-bottom: 23px;
            padding-left: 27px;
        }

        border-top-left-radius: 8px;
    }

    .cart-list {
        overflow-y: scroll;
        height: 330px;

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f2f2f2;
            border-radius: 7px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #bdbdbd;
            border-radius: 7px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .price-section {
        .promo-code-section {
            background-color: rgba($color: #f47b3426, $alpha: 0.15);
            padding: 18px 20px;
            border-radius: 8px;

            p {
                display: inline;
                @include font($farm-font-montserrat, 600, 16px);
                color: #1c1e1c;
            }
            span {
                cursor: pointer;
                float: right;
                @include font($farm-font-montserrat, 400, 14px);
                color: #f47b34;
            }
        }
        hr {
            background-color: #e0e0e0;
            height: 2px;
        }

        .sub-total {
            margin-bottom: 10px;
            .sub-total-inner,
            .sub-total-amount {
                display: inline;
                @include font($farm-font-montserrat, 600, 16px);
                color: #3e423f;
            }
            .sub-total-amount {
                float: right;
            }
        }

        .delivery-charge {
            .delivery-charge-inner,
            .delivery-charge-amount {
                display: inline;
                @include font($farm-font-montserrat, 400, 16px);
                color: #3e423f;
            }
            .delivery-charge-amount {
                float: right;
            }
        }

        .grand-total {
            margin-top: 16px;
            margin-bottom: 25px;
            .grand-total-inner,
            .grand-total-amount {
                display: inline;
                @include font($farm-font-montserrat, 600, 18px);
                color: #3e423f;
            }
            .grand-total-amount {
                float: right;
            }
        }

        .message {
            @include font($farm-font-montserrat, 400, 12px);
            color: #828282;
            margin-top: 23px;
        }
    }
}

.cart-item {
    display: flex;
    border-bottom: 1px solid #e0e0e0;

    .cart-img {
        width: 75px;
        height: 75px;
        margin-right: 15px;
        padding: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .cart-content {
        // flex: auto;
        width: 100%;
        .cart-content-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            p {
                @include font($farm-font-montserrat, 400, 16px);
                color: #1c1e1c;
            }
            .remove-item {
                background-color: #878b87;
                border-radius: 50%;
                min-width: 20px;
                min-height: 20px;

                cursor: pointer;

                svg {
                    transform: scale(0.7);
                    path {
                        fill: #fcfcfc;
                    }
                }
            }
        }

        .cart-meta {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            width: 180px;

            .price {
                @include font($farm-font-roboto, 500, 14px);
                color: #333333;
                margin-left: 23.27px;
            }
            .cart_quantity {
                display: flex;
                align-items: center;

                .cart-qty-minus,
                .cart-qty-plus {
                    width: 33.02px;
                    height: 28.44px;
                    line-height: 28.44px;
                    text-align: center;
                    background-color: #e8e8e8;
                    color: #fcfcfc;
                    font-size: 20px;
                    cursor: pointer;
                }

                .cart-qty-minus {
                    border-radius: 50% 0 0 50%;
                }
                .cart-qty-plus {
                    border-radius: 0 50% 50% 0;
                }

                .qty {
                    width: 45.87px;
                    height: 28.44px;
                    line-height: 28.44px;
                    text-align: center;
                    @include font($farm-font-roboto, 500, 11.1px);
                }
            }
        }
    }
}
