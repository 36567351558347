.meat-container-wrapper {
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
        padding-top: 62px;
    }

    .offer-container-inner {
        .offer-section {
            position: relative;
            padding: 16px;
            border-radius: 8px 8px 0px 0px;
            background-color: $meat-color-dark;
            min-height: 147px;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                padding: 20px;
                min-height: 200px;
            }

            @include media-breakpoint-up(lg) {
                min-height: 800px;
                border-radius: 8px 0px 0px 8px;
                padding: 52px 20px;
            }

            .offer-content {
                position: relative;
                z-index: 1;
                h2 {
                    @include font($farm-font-inter, 600, 20px);
                    color: #fcfcfc;
                }
                p {
                    @include font($farm-font-montserrat, 400, 14px);
                    color: #fcfcfc;
                }
                a {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    display: block;
                    text-decoration: none;
                    @include font($farm-font-montserrat, 600, 14px);
                    color: #f6f6f6;
                    width: 208px;
                    height: 40px;
                    text-align: center;
                    border: 2px solid #f7f7f7;
                    border-radius: 40px;

                    &:hover {
                        color: $white !important;
                        background-color: $meat-color-secondary-light;
                    }
                }

                @include media-breakpoint-up(md) {
                    h2 {
                        @include font($farm-font-inter, 600, 24px);
                    }
                }

                @include media-breakpoint-up(lg) {
                    h2 {
                        @include font($farm-font-inter, 600, 30px);
                    }

                    p {
                        @include font($farm-font-montserrat, 400, 16px);
                        color: #fcfcfc;
                    }

                    a {
                        margin-top: 165px;
                        @include font($farm-font-montserrat, 600, 16px);
                        width: 231px;
                        height: 55px;
                    }
                }
            }
            .offer-img {
                position: absolute;
                width: 264px;
                height: 402px;
                bottom: 0;
                right: 0;
                display: none;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }

        .offer-cards-container {
            flex: auto;
            display: grid;
            grid-template-columns: repeat(3, 340px);
            grid-template-rows: repeat(2, 402px);
        }
    }
}
.meat-container-wrapper {
    .product-header a {
        color: #aa3c3b;
        border-bottom: 2px solid #aa3c3b;

        padding-left: 4px;
        padding-right: 4px;
    }

    .meat-bar {
        .simplebar-scrollbar.simplebar-visible:before {
            background: $meat-color-dark;
        }
    }
}
.meat-promo {
    .promo-body .promo-card a {
        background-color: #aa3c3b !important;
    }
    .promo-banner-section {
        display: none !important;
    }
    .promo-body .promo-card {
        background-color: #f6e6e8 !important;
    }
}
.meat {
    .slick-list {
        margin: 0 -8px;
    }

    .slick-dots li button {
        background-color: #9da39e;
        height: 12px;
        width: 12px;
        border-radius: 6px;
    }
    .slick-dots li button:before {
        color: transparent;
    }
    .slick-dots li.slick-active button:before {
        color: transparent;
    }
    .slick-active {
        button {
            background-color: $meat-color-dark !important;
            width: 25px !important;
            margin-left: -5px;
        }
    }

    .see-all {
        display: none;
    }

    .categories-card:hover {
        .see-all-text {
            color: #aa3c3b;
        }

        .see-all {
            display: flex;
        }
    }
    .categories-card:hover {
        border: 1px solid #aa3c3b;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.14);
    }
}

.meat-cat-container {
    padding-top: 40px;

    .slick-prev {
        left: -10px;

        @include media-breakpoint-up(xxl) {
            left: -20px;
        }
    }

    .slick-next {
        right: -10px;

        @include media-breakpoint-up(xl) {
            right: -16px;
        }

        @include media-breakpoint-up(xxl) {
            right: -20px;
        }
    }

    .slick-prev,
    .slick-next {
        z-index: 10;
        height: auto;
        width: auto;

        &::before {
            display: none;
        }
    }

    .slick-dots {
        bottom: -30px;

        @include media-breakpoint-up(lg) {
            bottom: -40px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 62px;
    }
}
