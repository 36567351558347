.nav-container {
    background: $white;
    border-bottom: 1px solid #e0e0e0;

    .nav-container-inner {
        display: flex;
        align-items: center;
        align-content: center;
        max-width: 1440px;
        padding: 12px 16px;

        @include media-breakpoint-up(lg) {
            padding: 0px 24px;
        }
    }

    .nav-logo {
        height: 32px;

        @include media-breakpoint-up(lg) {
            height: 80px;
        }
    }

    .icon-wrapper {
        border: 1px solid $light-gray-1;
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;

        @include media-breakpoint-up(lg) {
            height: 32px;
            width: 32px;
        }
    }

    .cart-price {
        color: $secondary-color;
        font-size: 14px;
        font-weight: 600;
    }

    .nav-avatar {
        background-color: $secondary-color;
        vertical-align: 'middle';
        height: 28px;
        width: 28px;
        border: 2px solid $secondary-color;

        @include media-breakpoint-up(lg) {
            height: 32px;
            width: 32px;
        }
    }
}

.subnav-container {
    background: white;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    align-content: center;
    .subnav-text {
        color: #878b87;
        font-size: 16px;
    }
    .subnav-text-offer {
        color: #3e423f;
        font-size: 16px;
    }
    .new-badge {
        height: 20px;
        width: 35px;
        background-color: rgba(244, 123, 52, 0.2);
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-left: 5px;
    }
    .new-badge-text {
        color: #e97856;
        font-size: 12px;
    }
}

.app-breadcrumbs {
    a {
        color: $gray-4;
    }

    span:last-child a {
        color: $secondary-color;
    }
}

.nav-drawer {
    .d-header {
        .nav-d-avatar {
            background-color: $secondary-color;
            vertical-align: 'middle';
            height: 50px;
            width: 50px;
            border: 2px solid $secondary-color;
        }
    }

    .d-body {
        padding: 24px 0;

        .d-links {
            a {
                display: block;
            }
        }

        .d-menu {
            padding-top: 24px;

            a {
                display: flex;
                align-items: center;
                font-family: $farm-font-montserrat;
                font-size: 16px;
                color: $gray-3;
                margin-bottom: 24px;
            }
        }
    }

    .d-header,
    .d-links {
        padding-bottom: 24px;
        border-bottom: 1px solid $light-gray;
    }
}

.search-box {
    border-radius: 8px;
    overflow: hidden;

    .ant-input-wrapper {
        .ant-input {
            height: 44px;
            border-color: transparent;
            background-color: $background;
            font-size: 14px;
            font-family: $farm-font-montserrat;
            color: $gray;
            font-weight: 600;
            box-shadow: none;

            &::placeholder {
                font-size: 14px;
                font-family: $farm-font-montserrat;
                color: $gray-4;
            }
        }
        .ant-btn {
            border-color: transparent;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $background;
            border-left: none;
            outline: none;

            &[ant-click-animating-without-extra-node='true']::after {
                display: none;
            }
        }
    }
}

.nav-cart {
    .nav-cart-count {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.1rem;
        width: 1.1rem;
        top: -6px;
        left: -6px;
    }
}
