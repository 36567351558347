.recovery-form-section {
    .recovery-btn {
        margin-top: 30px;
        font-family: $farm-font-montserrat;
        font-weight: 600;
        border-radius: 40px;
        line-height: 0;
        cursor: pointer;
        transition: transform 0.3s ease;
        background-color: $secondary-color;
        color: #f2f2f2;
        border: none;
        width: 100%;
        height: 50px;
        &:hover {
            box-shadow: 0px 10px 22px rgba(232, 94, 13, 0.25);
        }
    }

    .skip {
        text-align: center;
        cursor: pointer;
        h3 {
            @include font($farm-font-montserrat, 600, 16px);
            margin-top: 30px;
            margin-bottom: 37px;
        }
    }

    .recovery-text {
        padding-bottom: 0;

        @include font($farm-font-montserrat, 400, 14px);
        padding-top: 6px;

        a {
            text-decoration: none;
            @include font($farm-font-montserrat, 700, 14px);
            color: #f47b34;
        }

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            text-align: center;
            margin-bottom: 32px;

            a {
                font-size: 16px;
            }
        }
    }
}
