.login-container {
    position: relative;
    background-color: $background-1;
    min-height: 100vh;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 67px;
    display: flex;
    flex-direction: column;

    &::before {
        display: none;
        content: '';
        background-image: url('../../../images/login-img2.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 350px;
        width: 350px;
        position: absolute;
        top: 0;
        left: 0;

        @media only screen and (min-width: 992px) {
            display: block;
        }
    }
    &::after {
        display: none;
        content: '';
        background-image: url('../../../images/login-img1.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 350px;
        width: 350px;
        position: absolute;
        bottom: 0;
        right: 0;

        @media only screen and (min-width: 992px) {
            display: block;
        }
    }

    .login-container-inner {
        width: 100%;
        max-width: 606px;
        min-height: 500px;
        padding: 0 16px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;

        .logo {
            width: 115px;
            height: 115px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .login-form {
            margin-top: 32px;
            width: 100%;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            padding: 32px 24px;
            background-color: #ffffff;
            z-index: 2;

            @include media-breakpoint-up(lg) {
                padding-left: 63px;
                padding-right: 63px;
            }

            form {
                .form-header {
                    @include font($farm-font-inter, 600, 20px);
                    color: #1c1e1c;
                    margin-bottom: 16px;

                    @include media-breakpoint-up(lg) {
                        font-size: 24px;
                    }
                }
                .form-text {
                    @include font($farm-font-montserrat, 400, 14px);
                    color: #828282;
                    margin-bottom: 8px;

                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                        margin-bottom: 12px;
                    }
                }
                .form-field {
                    padding-top: 16px;
                    position: relative;

                    @include media-breakpoint-up(lg) {
                        padding-top: 20px;
                    }

                    label {
                        display: block;
                        margin-bottom: 11px;
                        @include font($farm-font-montserrat, 400, 14px);
                        color: #878b87;
                    }

                    input[type='text'],
                    input[type='email'],
                    input[type='password'] {
                        width: 100%;
                        box-sizing: border-box;
                        border: 1px solid #e8e8e8;
                        border-radius: 8px;
                        background-color: #f7f7f7 !important;
                        padding: 12px 56px 12px 16px;
                        color: #878b87;
                        @include font($farm-font-montserrat, 400, 16px);
                    }

                    svg {
                        position: absolute;
                        right: 19px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                .forget-password-text {
                    margin-top: 19px;
                    margin-bottom: 27px;
                    @include font($farm-font-montserrat, 600, 14px);
                    color: #828282;

                    @media only screen and (min-width: 992px) {
                        font-size: 16px;
                    }
                }
                .login-btn {
                    font-family: $farm-font-montserrat;
                    font-weight: 600;
                    border-radius: 40px;
                    line-height: 0;
                    cursor: pointer;
                    transition: transform 0.3s ease;
                    background-color: $secondary-color;
                    color: #f2f2f2;
                    border: none;
                    width: 100%;
                    height: 50px;
                    &:hover {
                        box-shadow: 0px 10px 22px rgba(232, 94, 13, 0.25);
                    }
                }
            }
            .social-icon-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 29px;
                margin-bottom: 30px;

                .social-icon-text {
                    @include font($farm-font-montserrat, 400, 14px);
                    color: #878b87;
                    margin: auto 0;

                    @media only screen and (min-width: 992px) {
                        font-size: 16px;
                    }
                }
                .social-icon-inner {
                    display: flex;
                    align-items: center;

                    .social-icon {
                        cursor: pointer;
                        background-color: $bg-gray-1;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:first-child {
                            margin-right: 14px;
                        }
                    }
                }
            }
            hr {
                background-color: rgb(75, 75, 75);
                height: 1px;
                width: 100%;
                margin-bottom: 24px;
            }
            .forget-link {
                text-align: center;
                p {
                    margin-bottom: 0px;
                    @include font($farm-font-montserrat, 400, 14px);
                    color: #878b87;
                    a {
                        text-decoration: none;
                        @include font($farm-font-montserrat, 700, 14px);
                        color: $fg-orange;
                    }

                    @media only screen and (min-width: 992px) {
                        font-size: 16px;

                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .auth-footer {
        text-align: center;

        p {
            @include font($farm-font-montserrat, 400, 14px);
            color: #828282;
            margin-top: 32px;
            margin-bottom: 0;
            padding: 0 16px;
        }
    }
}
