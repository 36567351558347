.footer {
    width: 100%;
    .feature-section {
        .feature-divider {
            width: 1px;
            height: '100%';
            background-color: $gray-3;
        }

        .feature-text {
            color: #828282;
            font-size: 12px;
            font-weight: 600;
            font-family: $farm-font-montserrat;
        }

        @include media-breakpoint-up(lg) {
            .feature-text {
                font-size: 16px;
            }
        }
    }
    .footer-top {
        background-color: #e8e8e8;

        .follow-us-text {
            font-size: 14px;
            color: $gray-1;
            font-weight: 600;
            font-family: $farm-font-roboto;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size: 24px;
                font-weight: 500;
                text-transform: none;
                color: $dark;
            }
        }
        .footer-headings {
            color: $dark;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            font-family: $farm-font-roboto;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                font-weight: 700;
            }
        }
        .footer-links {
            color: #878b87;
            font-size: 14px;
            font-weight: 400;
            text-decoration: none;
            margin-bottom: 2rem;
            font-family: $farm-font-roboto;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }
        }
    }
    .footer-bottom {
        .footer-seperator {
            height: 2px;
            background-color: $light-gray;
            margin: 0;

            @media only screen and (min-width: 1300px) {
                margin: 0 34px;
            }
        }
        .icon-wrapper {
            border: 1px solid $brown;
            height: 47px;
            width: 47px;
            justify-content: center;
            border-radius: 50%;
            align-items: center;
            display: flex;
            background-color: $background;
        }
        .phone-text {
            color: $brown;
            font-size: 20px;
            font-weight: 500;
            font-family: $farm-font-roboto;
        }
        .operating-hour-text {
            color: $brown;
            font-size: 14px;
            font-family: $farm-font-roboto;
        }

        .footer-bottom-text {
            font-family: $farm-font-montserrat;
            color: #878b87;
            font-size: 12px;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
                font-family: $farm-font-roboto;
            }
        }
        .download-text {
            font-family: $farm-font-roboto;
            color: $dark;
            font-size: 16px;
            font-weight: 700;
            text-align: end;
        }
    }
}
