.radio-container {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .radio-outer-circle {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        border: 2px solid $secondary-color;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        transition: all 0.1s linear;
        background-color: $white;
    }

    .radio-inner-circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $secondary-color;
        transition: all 0.1s linear;
    }

    .unselected {
        border: 2px solid $gray-3;
    }
    .unselected-circle {
        width: 0;
        height: 0;
    }
    .helper-text {
        padding-right: 0.5 rem;
    }
}

.radio-content {
    padding-left: 2rem;
}
