.login-form {
    .login-form-inner {
        text-align: center;
        h2 {
            @include font($farm-font-inter, 600, 20px);
            margin-bottom: 16px;

            @media only screen and (min-width: 992px) {
                margin-top: 71px;
                font-size: 24px;
                margin-bottom: 24px;
            }
        }

        p {
            @include font($farm-font-montserrat, 400, 14px);
            color: #878b87;
            margin-bottom: 24px;

            @media only screen and (min-width: 992px) {
                margin-bottom: 29px;
                font-size: 16px;
            }
        }
        .checked-icon {
            margin: 29px auto;
            width: 100px;
            height: 100px;
            background-color: #b5ffb3;
            text-align: center;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                width: 145px;
                height: 145px;
            }
        }

        .otp-btn {
            font-family: $farm-font-montserrat;
            font-weight: 600;
            border-radius: 40px;
            line-height: 0;
            cursor: pointer;
            transition: transform 0.3s ease;
            background-color: $secondary-color;
            color: #f2f2f2;
            border: none;
            width: 100%;
            height: 50px;
            &:hover {
                box-shadow: 0px 10px 22px rgba(232, 94, 13, 0.25);
            }
        }
        hr {
            margin-top: 37px;
        }
        .otp-text {
            margin-bottom: 0px;

            @include font($farm-font-montserrat, 400, 14px);
            span {
                @include font($farm-font-montserrat, 700, 14px);
            }

            @include media-breakpoint-up(lg) {
                font-size: 16px;

                span {
                    font-size: 16px;
                }
            }
        }
    }
}

.opt_input {
    margin-bottom: 31px;
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px;

    @media only screen and (min-width: 992px) {
        margin-left: -10px;
        margin-right: -10px;

        div {
            margin: 0 10px;

            input {
                height: 50px;
            }
        }
    }

    div {
        margin: 0 5px;

        input {
            max-width: 50px !important;
            width: 100% !important;
            height: 45px;
            border-radius: 8px;
            background-color: #e8e8e8;
            border: none;
        }
    }
}
