.myorders-container {
    .site-card-border-less-wrapper {
        margin-top: 20px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        svg {
            margin-right: 5px;
        }
    }
    .delivery-status {
        float: right;
        span {
            color: $secondary-color;
            font-weight: 500;
            margin-left: 5px;
        }
    }
    .ant-card-extra {
        span {
            color: $gray-2;
        }
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: $gray-2;
    }
    .col-12 .row {
        .amounts {
            text-align: right;
        }
        .ant-card-head-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
        }
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
        }

        .ant-list-item-meta-title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
        }
        .ant-steps-item-finish
            > .ant-steps-item-container
            > .ant-steps-item-tail::after {
            background-color: $secondary-color;
        }
        .ant-steps-dot .ant-steps-item-tail::after,
        .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
            height: 10px;
        }

        .ant-steps-item-wait
            .ant-steps-item-icon
            > .ant-steps-icon
            .ant-steps-icon-dot {
            background: $gray-3;
            border: 6px solid #f6f6f6cc;

            top: -9px;
            left: -9px;
            height: 30px;
            width: 30px;
        }
        .ant-steps-item-process
            .ant-steps-item-icon
            > .ant-steps-icon
            .ant-steps-icon-dot,
        .ant-steps-item-finish
            .ant-steps-item-icon
            > .ant-steps-icon
            .ant-steps-icon-dot {
            background: $secondary-color;
            border: 6px solid #f6f6f6cc;
            top: -9px;
            left: -9px;
            height: 30px;
            width: 30px;
        }
        .ant-steps {
            padding: 20px 0 0 0;
        }

        .ant-steps-item-content {
            .ant-steps-item-title {
                padding-top: 10px;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
.order-list {
    padding: 20px 8px;
    .item-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: $dark;
    }

    .item-weight {
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        color: $gray-2;
    }
    .item-price {
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        color: $gray-3;
        font-weight: 700;
    }
}
.block-content {
    padding: 0 13px;
}
.mypanel-payment-detail {
    b {
        font-weight: 600;
        font-size: 18px;
    }
}
.myAddresses-container {
    .ant-list-split .ant-list-item {
        border-bottom: none;
    }
}
