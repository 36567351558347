.product-container {
    border-radius: 8px;

    @include media-breakpoint-up(lg) {
        padding: 15px 30px;
        background-color: $white;
    }

    .product-wrapper {
        .product-wrapper-left {
            .image-slider {
                margin-bottom: 40px;

                .image-gallery-thumbnail + .image-gallery-thumbnail {
                    margin-left: 12px;
                }

                .image-gallery-thumbnail {
                    width: 72px;
                    border-radius: 5px;
                    border: 1px solid $light-gray;
                    overflow: hidden;
                    background-color: $white;

                    .image-gallery-thumbnail-image {
                        object-fit: cover;
                    }
                }

                .image-gallery-image {
                    object-fit: cover;

                    @include media-breakpoint-up(lg) {
                        object-fit: contain;
                    }
                }

                .image-gallery-bullets {
                    bottom: -20px;

                    .image-gallery-bullet {
                        padding: 0px;
                        box-shadow: none;
                        border: none;
                        min-height: 8px;
                        min-width: 8px;
                        background-color: $gray-4;
                    }

                    .image-gallery-bullet.active {
                        transform: none;
                        min-height: 8px;
                        min-width: 16px;
                        border-radius: 20px;
                        background-color: $fg-orange;
                    }
                }
            }

            .product-discount {
                display: inline-block;
                background-color: $primary-color-2;
                font-family: $farm-font-roboto;
                font-size: 12px;
                font-weight: 500;
                color: $white;
                padding: 5px 25px;
                border-radius: 16px;
                margin-bottom: 20px;
            }
            .offer-info {
                background-color: #f2f2f2;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                padding: 25px 28px;
                margin-top: 60px;
                & p {
                    font-family: $farm-font-montserrat;
                    font-size: 16px;
                    font-weight: 600;
                    overflow-wrap: break-word;
                    color: $gray-1;
                }

                .home-delivery-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: 20px;

                    img {
                        padding: 20px 10px;
                        margin-right: 8px;
                        background-color: $secondary-color;
                        border-radius: 10px;
                    }
                }
                .discount-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        padding: 14px 10px;
                        margin-right: 8px;
                        background-color: $primary-color;
                        border-radius: 10px;
                    }
                    p {
                        color: #828282;
                    }
                }
            }
        }
        .product-wrapper-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .detail-header {
                @include media-breakpoint-up(lg) {
                    padding-bottom: 38px;
                }

                h2 {
                    font-family: $farm-font-inter;
                    font-weight: 600;
                    font-size: 18px;

                    @include media-breakpoint-up(lg) {
                        font-size: 24px;
                    }

                    .detail-like-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        border: 1px solid $gray-4;
                    }

                    .detail-liked {
                        border-color: $fg-orange;
                    }

                    .detail-liked-meat {
                        border-color: $meat-color-dark;
                    }
                }
                .detail-sub {
                    display: flex;
                    flex-direction: column;

                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        align-items: center;
                    }

                    .detail-sub__inner {
                        font-family: $farm-font-roboto;
                        font-size: 14px;
                        font-weight: 400;
                        color: #878b87;
                        padding-right: 20px;
                        margin: 0;

                        span {
                            color: #1c1e1c;
                        }

                        .category {
                            font-weight: 500;
                            text-transform: uppercase;
                        }

                        ul {
                            margin: 0 !important;
                            padding-left: 0 !important;
                            padding-right: 10px !important;
                            float: left;
                            li {
                                float: left;
                                list-style: none;
                            }
                        }
                    }
                }
            }
            .description {
                font-family: $farm-font-montserrat;
                font-weight: 400;
                font-size: 14px;
                color: #3e423f;
                padding-bottom: 18px;

                ul {
                    margin-left: 16px !important;
                }

                ul,
                li {
                    display: list-item !important;
                    list-style: disc !important;
                }

                p {
                    font-family: $farm-font-montserrat !important;
                    color: $gray !important;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-family: $farm-font-inter !important;
                }
            }

            .detail-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .detail-footer__inner {
                    span {
                        font-family: $farm-font-montserrat;
                        font-weight: 600;
                        font-size: 14px;
                        color: $gray-4;
                        text-decoration: line-through;
                        text-decoration-color: $gray-4;

                        @include media-breakpoint-up(lg) {
                            font-size: 16px;
                        }
                    }
                    .price {
                        margin-top: 14px;
                        margin-bottom: 9px;
                        font-family: $farm-font-inter;
                        font-weight: 600;
                        font-size: 20px;
                        color: #3e423f;

                        @include media-breakpoint-up(lg) {
                            font-size: 24px;
                        }
                    }
                    .unit-tag {
                        .unit-btn {
                            display: inline-block;
                            border: none;
                            background-color: #e8e8e8;
                            padding: 3px 12px;
                            margin-right: 10px;
                            color: $gray-4;
                            font-family: $farm-font-roboto;
                            font-weight: 500;
                            font-size: 16px;
                            border-radius: 16px;

                            &__active {
                                background-color: #82c664;
                                color: #fcfcfc;
                            }
                        }
                    }
                }

                .stock-tag {
                    padding: 8px 26px;
                    background-color: #f6f6f6;
                    font-family: $farm-font-roboto;
                    font-weight: 400;
                    font-size: 14px;
                    border-radius: 26px;
                    color: #828282;
                }
            }

            .product-btns {
                .product-whislist-btn {
                    font-family: $farm-font-roboto;
                    font-weight: 400;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }
}

// slider

.image-gallery-icon {
    filter: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    background-color: $white;
    height: 30px !important;
    width: 30px !important;
    color: $secondary-color;
    border-radius: 50%;
    border: 2px solid $secondary-color;
    box-shadow: none !important;
}

.image-gallery-left-nav {
    &:hover .image-gallery-svg {
        height: 30px !important;
        width: 30px !important;
        background-color: $secondary-color;
        border-radius: 50%;
        border: 2px solid #fcfcfc;
        color: $white;
        box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.25) !important;
    }
}
.image-gallery-right-nav {
    &:hover .image-gallery-svg {
        height: 30px !important;
        width: 30px !important;
        background-color: $secondary-color;
        border-radius: 50%;
        border: 2px solid #fcfcfc;
        color: $white;
        box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.25) !important;
    }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid $secondary-color !important;
}

.image-gallery-thumbnails-wrapper {
    margin-top: 40px;
}

.image-gallery-meat {
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus {
        border: 1px solid $meat-color-dark !important;
    }

    .image-gallery-bullet.active {
        background-color: $meat-color-dark !important;
    }
}
