.simplebar-scrollbar.simplebar-visible:before {
    background: $fg-orange;
}

.simplebar-content:after,
.simplebar-content:before {
    padding: 0 8px;
}

.category-wrapper {
    margin: 0 -16px;

    .simplebar-content {
        display: flex;

        margin: 0 -4px;

        @include media-breakpoint-up(lg) {
            margin: 0 -8px;
        }
    }
}

.features-container {
    padding-top: 40px;

    .slick-prev {
        left: -4px;
    }

    .slick-next {
        right: -4px;
    }

    .slick-dots {
        bottom: -30px;

        @include media-breakpoint-up(lg) {
            bottom: -40px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 62px;
    }
}
.category-img-container {
    background: #fcb79a;
    padding: 20px 0 0 0;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    position: relative;
    img {
        height: 70px;
        width: 70px;
        position: absolute;
        top: 0px;
        left: -20px;
    }
}

.categories-card {
    background-color: $white;
    border: 1px solid #e8e8e8;
    height: 100px;
    min-width: 80px;
    border-radius: 8px;
    flex-wrap: wrap;

    @include media-breakpoint-up(sm) {
        height: 125px;
    }

    @include media-breakpoint-up(lg) {
        height: 150px;
    }

    &:hover {
        cursor: pointer;

        border: 1px solid $secondary-color;

        @include media-breakpoint-up(lg) {
            box-shadow: 0px 14px 16px rgba(0, 0, 0, 0.14);
        }
        .see-all-text {
            font-size: 12px;
            font-family: $farm-font-montserrat;
            font-weight: 600;
            color: $secondary-color;
        }
    }

    .see-all-text {
        font-size: 12px;
        font-family: $farm-font-montserrat;
        font-weight: 600;
        color: $fg-orange;
    }

    .card-title {
        font-size: 10px;
        font-weight: 600;
        color: #1c1e1c;

        @include media-breakpoint-up(sm) {
            font-size: 12px;
        }

        @include media-breakpoint-up(md) {
            font-size: 15px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }
    .card-subtitle {
        color: #1c1e1c;
        font-size: 14px;
        font-family: $farm-font-montserrat;
    }
}
