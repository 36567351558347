.search-container {
    box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.14);
    top: 65px;
    left: 0;
    right: -176px;
    z-index: 100;
    max-width: 830px;
    border-radius: 8px;

    .border-left {
        border-left: 1px solid $light-gray;
    }
}

.search-card {
    border-bottom: 1px solid $light-gray;
}
