.container-wrapper-inner {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 45px;
        margin-bottom: 80px;
    }
}
.product-bought-inner {
    background-color: $light-gray-3;
    border-radius: 8px;

    // margin-left: 30px;
    .product-bought-head {
        font-family: $farm-font-inter;
        font-size: 20px;
        font-weight: 500;
        padding: 20px;
        margin-bottom: 0px;
        color: $dark;
        border-bottom: 1px solid $border;
    }

    .product-bought-cards-wapper {
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        .product-bought-cards {
            .product-bought-card {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-right: 15px;
                border-bottom: 1px solid #e0e0e0;
                display: flex;
                align-items: center;

                .image-section {
                    margin-right: 10px;
                    position: relative;
                    .discount {
                        position: absolute;
                        top: 6px;
                        left: 4px;
                        background-color: $primary-color-2;
                        border-radius: 12.8525px;
                        padding: 2px 7px;
                        font-size: 9.63934px;
                        font-family: $farm-font-roboto;
                        font-weight: 500;
                        color: #fcfcfc;
                    }
                    img {
                        width: 87px;
                        height: 105px;
                        border-radius: 7.39604px;
                        object-fit: cover;
                    }
                }
                .product-bought-body {
                    flex: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;

                    .product-title {
                        font-family: $farm-font-inter;
                        font-weight: 500;
                        font-size: 18px;
                        padding: 0 !important;
                    }

                    .product-bought-description {
                        width: 100%;

                        .price {
                            font-family: $farm-font-montserrat;
                            font-size: 14px;
                            font-weight: 500;
                            color: #4f4f4f;
                        }

                        .cart-quantity-section {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }
}
