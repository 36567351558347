.offer-container-wrapper {
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
        padding-top: 62px;
    }

    .simplebar-content {
        display: flex;
        margin: 0 -6px;

        @include media-breakpoint-up(lg) {
            margin: 0 -10px;
        }

        .offer-box {
            aspect-ratio: 64/39;
            max-height: 250px;
            min-width: 255px;
            flex: 1;
            display: flex;
            overflow: hidden;
            border-radius: 8px;
            position: relative;
            margin: 0 6px;

            .img-section {
                display: none;
                z-index: 1;
                position: absolute;
                bottom: -10px;
                right: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .offer-box-inner {
                z-index: 2;
                width: 100%;
                padding: 16px;

                h2 {
                    @include font($farm-font-montserrat, 600, 20px);
                    margin-bottom: 5px;
                }
                p {
                    @include font($farm-font-montserrat, 400, 14px);
                    margin-bottom: 10px;
                }
                a {
                    @include font($farm-font-montserrat, 600, 14px);
                    border-radius: 40px;
                    text-decoration: none;
                    text-align: center;
                    line-height: 40px;
                    width: 150px;
                    height: 40px;
                    display: block;
                }
            }

            @include media-breakpoint-up(lg) {
                aspect-ratio: 38/25;
                margin: 0 10px;

                .img-section {
                    display: block;
                }

                .offer-box-inner {
                    padding: 30px 20px;

                    h2 {
                        @include font($farm-font-inter, 600, 30px);
                    }
                    p {
                        @include font($farm-font-montserrat, 400, 16px);
                    }
                    a {
                        @include font($farm-font-montserrat, 600, 16px);
                    }
                }
            }
        }
        .meat-box {
            background-color: $meat-color;
        }
        .veg-box {
            background-color: $primary-color;
        }
        .fruits-box {
            background-color: #fad3bd;
        }
        .meat-offer,
        .fruit-offer {
            color: #aa3c3b;
        }
        .meat-title,
        .fruit-title {
            color: #1c1e1c;
        }
        .meat-sub-title,
        .fruit-sub-title {
            color: #1c1e1c;
        }
        .veg-offer {
            color: #e8e8e8;
        }
        .veg-title {
            color: #fcfcfc;
        }
        .veg-sub-title {
            color: #f6f6f6;
        }
        .meat-button {
            color: #f2f2f2;
            background-color: #aa3c3b;

            &:hover {
                color: #fcfcfc !important;
                opacity: 0.9;
            }
        }
        .veg-button {
            color: #40ae49;
            background-color: #fcfcfc;

            &:hover {
                color: #40ae49 !important;
                opacity: 0.9;
            }
        }
        .fruit-button {
            color: #fcfcfc;
            background-color: #f47b34;

            &:hover {
                color: #fcfcfc !important;
                opacity: 0.9;
            }
        }
    }
}
