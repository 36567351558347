.product-container-wrapper {
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
        padding-top: 62px;
    }

    .product-header {
        a {
            @include font($farm-font-inter, 500, 12px);
            text-decoration: none;
            color: $secondary-color-dark;
            border-bottom: 2px solid $secondary-color-dark;
            padding-bottom: 4px;
            padding-left: 4px;
            padding-right: 4px;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
                padding-bottom: 8px;
            }
        }
    }
}

.product-card-wrapper {
    .simplebar-content {
        display: flex;
    }
}
