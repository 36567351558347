.app-select-dropdown {
    .app-select-item {
        min-height: auto;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: $gray-5;
        font-weight: 600;
        background-color: $white;

        .address-label {
            padding-left: 16px;
        }

        .price-label {
            font-weight: 400;
        }
    }
}

.app-select {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 8px;
        border: 1px solid $light-gray;
        background-color: #f6f6f6;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
        .ant-select-selector {
        height: 45px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        line-height: 42px !important;
    }

    .ant-select-selection-placeholder {
        font-family: $farm-font-montserrat;

        color: $gray-4;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: $secondary-color-light;
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
        border-color: $secondary-color-light !important;
        box-shadow: 0 0 0 2px rgba(244, 123, 52, 0.2) !important;
    }

    .ant-select-arrow {
        display: flex;
        align-items: center;
        width: auto;
        right: 12px;

        svg {
            font-size: 16px;
        }
    }
}
