// fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
$farm-font-inter: 'Inter', sans-serif;
$farm-font-roboto: 'Roboto', sans-serif;
$farm-font-montserrat: 'Montserrat', sans-serif;

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
}
body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    background: $background-2 !important;
    font-family: $farm-font-inter;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $farm-font-inter;
    font-weight: 700;
}

p {
    font-family: $farm-font-inter;
}

a {
    font-family: $farm-font-inter;
    text-decoration: none !important;

    &:hover {
        color: $secondary-color !important;
    }
}
ul {
    font-family: $farm-font-inter;
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
}
li {
    font-family: $farm-font-inter;
    display: block;
}

.text-inter {
    font-family: $farm-font-inter;
}

.text-roboto {
    font-family: $farm-font-roboto;
}

.text-montserrat {
    font-family: $farm-font-montserrat;
}

.bg-color {
    background-color: $background;
}

.bg-orange {
    background-color: $secondary-color;
}

.bg-white {
    background-color: $white;
}

.bg-gray {
    background-color: $bg-gray;
}

.bg-gray-1 {
    background-color: $bg-gray-1;
}

.bg-border {
    background-color: $border;
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-light-gray-1 {
    background-color: $light-gray-1;
}

.bg-light-gray-2 {
    background-color: $light-gray-2;
}

.bg-light-gray-3 {
    background-color: $light-gray-3;
}

.bg-green-light {
    background-color: $primary-light;
}

.text-green {
    color: $primary-color;
}

.text-orange {
    color: $secondary-color;
}

.text-orange-light {
    color: $secondary-color-light;
}

.text-border {
    color: $border;
}

.text-gray {
    color: $gray;
}

.text-gray-1 {
    color: $gray-1;
}

.text-gray-2 {
    color: $gray-2;
}

.text-gray-3 {
    color: $gray-3;
}

.text-gray-4 {
    color: $gray-4;
}

.text-gray-5 {
    color: $gray-5;
}

.text-gray-6 {
    color: $gray-6;
}

.text-gray-7 {
    color: $gray-7;
}

.text-gray-8 {
    color: $gray-8;
}

.text-light-gray {
    color: $light-gray;
}

.text-light-gray-1 {
    color: $light-gray-1;
}

.text-light-gray-2 {
    color: $light-gray-2;
}

.text-dark-color {
    color: $dark;
}

.text-dark-1 {
    color: $dark-1;
}

.text-meat-dark {
    color: $meat-color-dark;
}

.text-meat-secondary {
    color: $meat-color-secondary;
}

.text-brown {
    color: $brown;
}

.text-400 {
    font-weight: 400;
}

.text-500 {
    font-weight: 500;
}

.text-600 {
    font-weight: 600;
}

.text-700 {
    font-weight: 700;
}

.text-10 {
    font-size: 0.625rem;
}

.text-12 {
    font-size: 0.75rem;
}

.text-14 {
    font-size: 0.875rem;
}

.text-16 {
    font-size: 1rem;
}

.text-18 {
    font-size: 1.125rem;
}

.text-20 {
    font-size: 1.25rem;
}

.text-24 {
    font-size: 1.5rem;
}

.text-30 {
    font-size: 1.875rem;
}

.text-36 {
    font-size: 2.25rem;
}

.text-48 {
    font-size: 3rem;
}

.text-56 {
    font-size: 3.5rem;
}

.button-main {
    background-color: $secondary-color;
    align-items: center;
    justify-content: center;
    color: #fcfcfc;
    border-radius: 8px;
    display: flex;
    font-family: $farm-font-roboto;
    font-size: 14px;
    border: none;
    outline: none;
    padding: 8px 12px;

    &:hover {
        background-color: $secondary-color;
        opacity: 0.8;
        color: #fcfcfc;
    }
}

.landing-title {
    font-size: 18px;
    font-weight: 600;
    color: $gray-1;
    font-family: $farm-font-inter;

    @include media-breakpoint-up(lg) {
        font-size: 2rem;
    }
}

.container-wrapper {
    padding-left: 5rem;
    padding-right: 5rem;
}

.cursor-pointer {
    cursor: pointer;
}

@mixin font($ff, $fw, $fs) {
    font-family: $ff;
    font-weight: $fw;
    font-size: $fs;
}

.text-underline {
    text-decoration: underline;
}

.text-strike {
    text-decoration: line-through;
}

.object-cover {
    object-fit: cover;
}

.object-contain {
    object-fit: contain;
}

.text-hover-orange {
    &:hover * {
        color: $secondary-color !important;
    }
}

.text-hover-meat {
    &:hover * {
        color: $meat-color-dark !important;
    }
}

.text-hover-gray {
    &:hover * {
        color: $gray-2 !important;
    }
}

.rounded-4 {
    border-radius: 0.5rem;
}

.app-container {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1234px;
}

.fade-in {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated {
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.pulse {
    animation-name: pulse;
    animation-duration: 1s;
}

.text-line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-line-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.loader-overlay {
    z-index: 9999;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-arrow-btn {
    outline: none;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
}

.custom-arrow {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-color: $secondary-color-light;
    border-style: solid;
    background-color: $white;

    &:not(.arrow-sm) {
        @include media-breakpoint-up(lg) {
            height: 40px;
            width: 40px;
        }
    }

    svg {
        stroke: $secondary-color-light;
        height: 32px;
        width: 32px;

        @include media-breakpoint-up(lg) {
            height: 28px;
            width: 28px;
        }
    }

    &:hover {
        box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.25);
        border-color: $light-gray-2;
        background-color: $secondary-color;

        svg {
            stroke: $light-gray-2;
        }
    }
}

.arrow-meat {
    border-color: $meat-color-light;
    background-color: $light-gray-2;

    svg {
        stroke: $meat-color-light;
    }

    &:hover {
        box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.25);
        background-color: $meat-color-dark;
    }
}
