// colors
$black: #000;
$white: #fff;
$primary-color: #40ae49;
$secondary-color: #f47b34;
$secondary-color-dark: #e85e0d;
$secondary-color-light: #f8aa7d;
$primary-color-2: #82c664;
$meat-color: #f7d5d4;
$meat-color-2: #e97856;
$meat-color-dark: #aa3c3b;
$meat-color-light: #e8b3b9;
$meat-color-secondary: #e97856;
$meat-color-secondary-light: #cb6862;
$red: #d3232a;
$fg-orange: #f47b34;
$bg-gray: #f9f9f9;
$bg-gray-1: #f7f7f7;
$gray: #3e423f;
$gray-1: #333333;
$gray-2: #828282;
$gray-3: #878b87;
$gray-4: #9da39e;
$gray-5: #4f4f4f;
$gray-6: #a0a3bd;
$gray-7: #747474;
$gray-8: #595959;
$light-gray: #e8e8e8;
$light-gray-1: #bdbdbd;
$light-gray-2: #fcfcfc;
$light-gray-3: #f2f2f2;
$light-gray-4: #f6f6f6;
$border: #e0e0e0;
$dark: #1c1e1c;
$dark-1: #212121;
$primary-light: #b5ffb3;
$primary-dark: #0cae19;
$background: #f6f6f6;
$background-1: #f9f9f9;
$background-2: #fcfcfc;
$brown: #4f2c1d;

$enable-negative-margins: true;

$enable-rounded: false;

// Break points
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1300px
);
