.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $fg-orange;
    border-color: $fg-orange;
}

.ant-checkbox-inner {
    height: 20px;
    width: 20px;
    border: 2px solid $gray-2;
    border-radius: 4px;
}

.ant-checkbox-checked::after {
    border: 2px solid $fg-orange;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $fg-orange;
}

.search-dropdown {
    @include font($farm-font-montserrat, 600, 1rem);

    .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }

    .ant-select-selection-item {
        @include media-breakpoint-down(lg) {
            @include font($farm-font-montserrat, 600, 14px);
            padding-right: 14px !important;
        }
    }
}

.search-checkbox {
    .ant-checkbox-wrapper {
        padding-bottom: 0.5rem;

        span {
            @include font($farm-font-roboto, 400, 1rem);
            color: $gray-2;
        }
    }

    .ant-checkbox-inner {
        &::after {
            display: none;
        }
    }
}

.checkbox-dark,
.checkbox-group-dark {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $meat-color-dark;
        border-color: $meat-color-dark;
    }

    .ant-checkbox-checked::after {
        border: 2px solid $meat-color-dark;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $meat-color-dark;
    }
}

.checkbox-dark {
    &:hover {
        .ant-checkbox-inner {
            border-color: $meat-color-dark;
        }
    }
}

.checkbox-group-dark {
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $meat-color-dark;
    }
}

.search-tag {
    color: $secondary-color;
    border-radius: 4px;
    border: 1px solid $secondary-color;

    .ant-tag-close-icon {
        vertical-align: middle;
        color: $secondary-color;
    }
}

.search-tag-meat {
    color: $meat-color-dark;
    border: 1px solid $meat-color-dark;

    .ant-tag-close-icon {
        color: $meat-color-dark;
    }
}

.filter-drawer {
    .ant-drawer-content {
        border-radius: 8px 8px 0px 0px;
        background: $light-gray-2;

        .ant-drawer-body {
            padding: 24px 16px;
        }
    }
}
