.aboutUs-wrapper {
    .slick-prev:before,
    .slick-next:before {
        color: transparent;
    }
    .slick-prev {
        left: -15px;
    }
    .slick-next {
        right: -15px;
    }
    .slick-center.slick-current {
        opacity: 1;
    }

    .slick-slide {
        opacity: 0.7;
    }
    .slick-dots {
        bottom: -70px !important;
        li {
            width: 12px;
            height: 12px;
            button:before {
                font-size: 20px;
                width: -12px;
                height: -12px;
            }
        }
        .slick-active {
            margin-right: 24px;
            .custom-slick-dots {
                background: $secondary-color;
                width: 32px;
                border-radius: 8px;
                margin-right: 60px;
            }
        }
    }

    .custom-slick-dots {
        height: 12px;
        width: 12px;
        background: $gray-4;
        border-radius: 50%;
    }
    .blog-container {
        .slick-prev {
            left: -5px;
        }
        .slick-next {
            right: -5px;
        }
    }

    .banner-container {
        display: block;

        .banner-inner {
            max-width: 1440px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 200px;
            padding: 16px;

            @include media-breakpoint-up(lg) {
                min-height: 300px;
            }
        }

        h2 {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            color: $dark;

            @include media-breakpoint-up(lg) {
                font-size: 56px;
                line-height: 4rem;
                padding-bottom: 10px;
            }
        }
        p {
            text-align: center;
            max-width: 991px;
            margin: 0 auto;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: $gray-3;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 32px;
            }
        }

        background-color: #f6f6f6;
        text-align: center;
    }
    .aboutUs-container {
        padding-top: 28px;
        background-color: $white;

        @include media-breakpoint-up(lg) {
            padding-top: 64px;
        }

        h4 {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;

            @include media-breakpoint-up(lg) {
                font-size: 40px;
                line-height: 52px;
            }
        }
        .what-we-do {
            h4 {
            }
        }
        p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $gray-3;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 38px;
            }
        }
        .contact-us-btn {
            margin-top: 20px;
            margin-bottom: 20px;
            border: 2px solid $secondary-color;
            box-sizing: border-box;
            border-radius: 40px;
            text-align: center;
            background-color: transparent;
            color: $secondary-color;
            padding: 6px 60px;

            @include media-breakpoint-up(lg) {
                margin-top: 60px;
            }
        }
        section {
            padding-bottom: 40px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 80px;
            }
        }
        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
        .our-process-container {
            text-align: center;
            background: $secondary-color;
            padding: 24px 16px 80px;
            border-radius: 8px;

            @include media-breakpoint-up(lg) {
                padding: 70px 50px 170px;
            }

            h4 {
                margin-bottom: 28px;
                color: $white;
                font-size: 40px;
                font-weight: 600;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 48px;
                }
            }
            h5 {
                margin-bottom: 0;
                color: $white;
                text-transform: uppercase;
                height: 35px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 16px;
                }
            }
            p {
                color: $white;
                line-height: 18px;
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
            }
            b {
                color: $secondary-color;
                font-size: 2.5rem;
            }
            .steps-content {
                padding: 0 15px;
                p {
                    margin-bottom: 1.5rem;
                    height: 54px;
                }
            }
            .step-item {
                .timeline-number {
                    background: $white;
                    border-radius: 50%;
                    padding: 20px;
                    height: 80px;
                    width: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    position: relative;
                    z-index: 1;
                }
                .line {
                    height: 2px;
                    background-color: #fff;
                    position: absolute;
                    width: 100%;
                    bottom: 40px;
                    z-index: 0;
                }
                .first-line {
                    width: 50%;
                    left: 50%;
                }
                .last-line {
                    width: 50%;
                    right: 50%;
                }
            }
        }
        .counts-container {
            .counts-row {
                width: 90%;
                background: $light-gray-2;
                box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.14);
                border-radius: 8px;
                margin: -90px auto 0px auto;
                padding: 40px 10px;
                text-align: center;

                h4 {
                    color: $secondary-color;
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 52px;
                }
                p {
                    font-family: 'Montserrat', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                    color: $gray-3;
                    margin-bottom: 0;
                }
            }
        }
    }

    .aboutUs-promo-container {
        background: $white;

        .promo-container-wrapper {
            padding: 0;
            .promo-banner-section {
                margin: 0;
            }
        }

        button {
            border: none;
            border-radius: 40px;
            padding: 10px 28px;
        }
        span {
            line-height: 20px;
        }
        h3 {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            color: #f2f2f2;
        }
    }

    .blog-container,
    .testimonial-container {
        padding-bottom: 8.125rem;
        background: $white;

        h4 {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;

            color: $dark;
            background: $white;
            margin-bottom: 28px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 48px;
                font-size: 40px;
                line-height: 52px;
            }
        }
        .slick-prev,
        .slick-next {
            &:hover {
                border: 2px solid $light-gray-2;
                background-color: $secondary-color;
                box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.25);
                svg path {
                    fill: $white;
                }
                &::before {
                    color: transparent;
                }
            }
            z-index: 999;
            width: 32px;
            height: 32px;
            line-height: 29px;
            text-align: center;
            border: 2px solid $secondary-color;
            border-radius: 50%;
        }
        .slick-prev {
            svg {
                position: absolute;
                left: 9px;
                top: 7px;
            }
        }
        .slick-next {
            svg {
                position: absolute;
                left: 3px;
                top: 2px;
                path {
                    fill: $secondary-color;
                }
            }
        }
    }

    .blog-container {
        padding-top: 5rem;
    }

    .testimonial-card-container {
        background: $white;
        border-radius: 8px 8px 0px 0px;
        filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.14));
        text-align: center;
        margin: 10px 30px 0 0;
        p,
        b,
        span,
        div {
            font-family: 'Montserrat', sans-serif;
        }
        p {
            padding: 24px;
            margin-bottom: 20px;
            line-height: 32px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: $gray;
        }
        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            color: $light-gray-2;
            margin-top: 24px;
        }
        address {
            font-size: 14px;
            line-height: 18px;
            color: $light-gray-2;
            margin: 0;
        }
        .testimonial-person-info {
            background: $gray;
            span {
                margin-top: -35px;
                background-color: $secondary-color;
            }
        }
        .testimonial-ratings-container {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            background: $gray;
            padding: 20px 0;
            border-radius: 0 0 8px 8px;
            img {
                margin-right: 2px;
            }
        }
    }
    .blog-card-container {
        margin-top: 10px;
        padding: 0 10px;
        picture {
            img {
                border-radius: 8px;
                margin-bottom: 20px;
                width: 100%;
            }
        }
        h4 {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: $dark;
            margin-top: 10px;
            margin-bottom: 2px;
        }
        p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 32px;
            color: $gray-3;
        }
        span {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: $gray;
        }
    }

    .newsletter-container {
        margin: 0;
    }
}

@media only screen and (max-width: 991px) {
    .line {
        display: none;
    }
    .step-item {
        margin-bottom: 1.5rem;
    }
    .steps-content p {
        height: auto !important;
    }
}
@media only screen and (max-width: 1200px) and (min-width: 601px) {
    .blog-card-container,
    .testimonial-card-container {
        min-width: 200px !important;
    }
}
