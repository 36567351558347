.ant-btn-link {
    color: $gray-2;
}
.ant-btn-link:hover {
    color: $secondary-color-light;
}
.ant-btn-primary {
    color: $white;
    border-color: $secondary-color;
    background: $secondary-color;
    border-radius: 20px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    border-color: $secondary-color-light;
    background: $secondary-color-light;
}
.ant-tabs {
    width: 100%;
}
.ant-card {
    height: 100%;
}
.ant-modal-content {
    border-radius: 8px;
}
.ant-modal-header {
    border-radius: 8px 8px 0 0;
}
.ant-modal-close-x {
    left: -33px;
    top: -50px;
    color: $white;
    position: absolute;
}
.ant-modal-title {
    h4 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #333333;
        margin-bottom: 0;
    }
    span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $gray-2;
    }
}
.ant-modal-body p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $gray;
}
.icon-btn {
    border: none;
    background: none;
}
.icon-btn:hover {
    color: $secondary-color;
}

.grey-font-16 {
    font-size: 14px;
    line-height: 16px;
    color: $gray-2;
}

.dark-bold-font {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
}
.dark-thin-font {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: $dark;
}
.subnav-container {
    border-bottom: none;

    .ant-tabs-tab-active .nav-title {
        color: $secondary-color;
        font-weight: 500;
    }

    .nav-title {
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.6;
        color: $gray-2;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: 500;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
        padding: 16px 18px;
    }
}
.btn-primary-bordered {
    border: 1px solid $secondary-color;
    color: $secondary-color;
}
.btn-grey {
    border: none;
    background: $light-gray-4;
}
.panel-banner-container {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f9e1d4;

    @include media-breakpoint-up(lg) {
        height: 300px;
    }
}
.avatar-item-profile {
    .add-image-btn {
        border: none;
        background: $secondary-color;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 0px;
        color: $white;
        position: absolute;
        top: 20px;
        right: -12px;
    }
    .avatar-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
        margin-bottom: 0;
        font-family: $farm-font-inter;

        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
    }
    .avatar-contact {
        font-size: 14px;
        line-height: 21px;
        font-family: $farm-font-montserrat;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }
    }
}

.mobile-sidenav {
    padding: 16px 16px;
    border-bottom: 1px solid $light-gray;

    p {
        font-size: 16px;
        font-family: $farm-font-inter;
        font-weight: 400;
        color: $gray-3;
    }
}

.subnav-container {
    .ant-tabs-nav {
        display: block;
        width: 240px;

        @include media-breakpoint-up(xl) {
            width: 300px;
        }
    }
    .ant-tabs-nav-list {
        background-color: $white;
        border-radius: 5px;
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
        border-left: none;
    }
    .ant-tabs-tab {
        font-size: 16px !important;
        margin: 0 !important;
        border-bottom: 1px solid $light-gray;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $secondary-color !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }

    .ant-tabs-ink-bar {
        background: $secondary-color;
    }
}

.order-list {
    .ant-spin-container {
        padding: 20px;
    }
    .ant-list-item {
        border: none !important;
        padding: 4px 0 !important;
    }
}
.mypanel-delivery-detail {
    padding: 20px 30px;
    .block-content {
        margin-bottom: 10px;
    }
}

.mypanel-payment-detail,
.mypanel-payment-options {
    padding: 20px 40px;
}
.myAddresses-container {
    .ant-list-item-meta-content {
        margin: 0 10px;
    }
    .ant-list-item-meta-avatar {
        margin-right: 5px;
        background: $secondary-color;
        padding: 2px 4px;
        border-radius: 5px;
        color: $white;
    }
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        color: $white;
    }
    .ant-radio-button-wrapper-checked {
        border: none !important;
        background: $secondary-color !important;
    }
}
.dashboard-container {
    .row {
        .content-title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 20px;
        }
    }
    .site-card-border-less-wrapper {
        .order-col,
        .status-col,
        .price-col {
            margin-bottom: 10px;
            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                align-items: center;
                color: $gray-3;
            }
            b {
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;
                align-items: center;
                color: $dark;

                @include media-breakpoint-up(md) {
                    font-size: 16px;
                }
            }
        }
        .price-col {
            text-align: right;
        }
        .status-col {
            text-align: center;
            b {
                color: $gray-3;
            }
        }
        .ant-card-body {
            position: relative;
            padding: 10px 24px;
            overflow: hidden;
        }
    }
}
.wallet-history {
    .ant-table-tbody > tr > td {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $gray-2;
        padding: 16px 8px;
    }
    .ant-table-thead > tr > {
        th {
            background: $light-gray-4;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
            color: $gray-2;
            padding: 4px 8px;
            border: none;

            &::before {
                display: none;
            }
        }
    }
}

.myWallet-container .myWallet-title,
.shopping-wishlist-title,
.myAddresses-container .address-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
}
.myAddresses-container {
    .ant-list-item {
        padding: 22px 0;
    }
    .locationIcon {
        path {
            stroke: $gray-2;
        }
    }
}
.wishlist-container {
    .ant-card-body {
        padding: 0;
    }
    .product-category {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: $gray-2;
        margin-bottom: 5px;
    }
    .product-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: $dark;
    }
    .product-price {
        margin-top: 15px;
        :nth-child(1) {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 34px;
            color: $secondary-color;
            margin-right: 5px;
        }
        :nth-child(2) {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $gray;
            margin-right: 5px;
        }
        :nth-child(3) {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $gray-4;
            text-decoration: line-through;
        }
    }
    .action-btn {
        display: flex;
        align-items: center;
    }
}

.profile-menu {
    max-width: 242px;
    width: 100%;

    .ant-popover-arrow {
        width: 33px;
        height: 29px;

        .ant-popover-arrow-content {
            width: 33px;
            height: 33px;
            border: 1px solid $light-gray;
            border-radius: 5px;
            box-shadow: none;
        }
    }

    .ant-popover-inner {
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid $light-gray;
        box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.14);
    }

    .ant-popover-inner-content {
        padding: 0;
    }
}

.menu-container {
    a,
    .logout-btn-container {
        padding: 12px 20px;
    }

    a {
        border-bottom: 1px solid $bg-gray-1;
        display: flex;
        align-items: center;
        font-family: $farm-font-inter;
        font-size: 16px;
        color: $gray-2;
    }

    .logout-btn-container {
        .logout-btn {
            width: 100%;
            height: 34px;
            background: $secondary-color;
            border: none;
            border-radius: 22px;
            color: $light-gray-2;
            font-size: 16px;
            font-family: $farm-font-inter;
            display: flex;
            align-items: center;
            justify-content: center;

            &:focus {
                outline: 4px solid rgba(232, 94, 13, 0.3) !important;
            }

            &:hover,
            &:focus {
                box-shadow: 0px 10px 16px rgba(232, 94, 13, 0.25);
                background-color: $secondary-color;
                color: $light-gray-2;
            }
        }
    }
}

.update-otp-container {
    margin: 0 -4px;
    .update-otp-input {
        margin: 0 4px;

        input {
            height: 40px;
            width: 40px !important;
            font-family: $farm-font-montserrat;
            border-radius: 4px;
            border: 1px solid $light-gray;

            &:focus {
                outline: 2px solid $secondary-color-light;
            }
        }
    }
}

.offer-redeem-container {
    display: flex;

    .offer-redeem-code {
        background-color: $light-gray-4;
        border: 1px dashed $border;

        span {
            font-family: $farm-font-montserrat;
            font-size: 16px;
            color: $gray-3;
        }
    }

    .copy-btn {
        outline: none;
        border: 1px solid $fg-orange;
        background-color: $fg-orange;
        color: $white;
        font-family: $farm-font-montserrat;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            background-color: $secondary-color-dark;
            box-shadow: 0px 10px 22px rgba(232, 94, 13, 0.25);
        }
    }
}

@import './myOrder';
@import '../../pages/checkout';
