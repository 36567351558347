.blog-share-section {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
}

.blog-detail-container {
    .blog-description {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $farm-font-inter !important;
            margin-bottom: 0.875rem !important;
        }

        p,
        li {
            font-family: $farm-font-montserrat !important;
            margin-bottom: 1rem !important;

            * {
                font-family: $farm-font-montserrat !important;
                margin-bottom: 1rem !important;
            }
        }

        img {
            max-width: 100%;
        }
    }

    .related-blog-container {
        margin-top: 1rem;
        margin-bottom: 100px;

        .blog-card-container {
            margin-top: 10px;
            padding: 0 8px;
        }
    }

    .slick-list {
        margin: 0 -16px;

        @include media-breakpoint-up(lg) {
            margin: 0 -8px;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: transparent;
    }

    .slick-prev,
    .slick-next {
        &:hover {
            border: 2px solid $light-gray-2;
            background-color: $secondary-color;
            box-shadow: 0px 7px 12px rgba(244, 123, 52, 0.25);
            svg path {
                fill: $white;
            }
            &::before {
                color: transparent;
            }
        }
        z-index: 999;
        width: 32px;
        height: 32px;
        line-height: 29px;
        text-align: center;
        border: 2px solid $secondary-color;
        border-radius: 50%;
    }
    .slick-prev {
        left: -15px;
        svg {
            position: absolute;
            left: 9px;
            top: 7px;
        }
    }
    .slick-next {
        right: -15px;
        svg {
            position: absolute;
            left: 3px;
            top: 2px;
            path {
                fill: $secondary-color;
            }
        }
    }

    .slick-slide {
        opacity: 0.7;
    }

    .slick-center,
    .slick-current {
        opacity: 1;
    }

    .slick-dots {
        bottom: -48px !important;
        li {
            width: 12px;
            height: 12px;
            button:before {
                font-size: 20px;
                width: -12px;
                height: -12px;
            }
        }
        .slick-active {
            margin-right: 24px;
            .custom-slick-dots {
                background: $secondary-color;
                width: 32px;
                border-radius: 8px;
                margin-right: 60px;
            }
        }
    }

    .custom-slick-dots {
        height: 12px;
        width: 12px;
        background: $gray-4;
        border-radius: 50%;
    }
}

.blog-card-container {
    margin-top: 10px;
    padding: 0 20px;

    picture {
        img {
            border-radius: 8px;
            margin-bottom: 20px;
            width: 100%;
            height: 266px;
            object-fit: cover;
        }
    }
    h4 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $dark;
        margin-top: 10px;
        margin-bottom: 2px;
    }
    p,
    em {
        font-family: 'Montserrat', sans-serif !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 32px !important;
        color: $gray-3 !important;
    }
    span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $gray;
    }
}

.blog-comment-input {
    outline: 1px solid $border;
    border: 1px solid $border;
    background-color: $background;
    font-family: $farm-font-montserrat;
    font-size: 14px;

    &::placeholder {
        color: $gray-4;
    }
}
