.app-input {
    border-radius: 8px;
    background-color: #f6f6f6;
    border-color: $light-gray;

    .ant-input {
        font-family: $farm-font-montserrat;
        line-height: 1.9;
        background-color: #f6f6f6;

        &::placeholder {
            color: $gray-4;
        }
    }
}

.ant-form-item-explain-warning {
    color: #ff4d4f;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-color: #ff4d4f;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    border-color: #ff7875;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $secondary-color-light;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: $secondary-color-light;
    box-shadow: 0 0 0 2px rgba(244, 123, 52, 0.2);
}

.app-form {
    .ant-form-item-label {
        label {
            color: $dark;
        }
    }
}

.app-datepicker {
    border-radius: 8px;
    background-color: #f6f6f6;
    border-color: $light-gray;

    .ant-picker-large .ant-picker-input > input {
        font-family: $farm-font-montserrat;
        line-height: 1.9;
        background-color: #f6f6f6;

        &::placeholder {
            color: $gray-4;
        }
    }
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(244, 123, 52, 0.2);
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: $secondary-color-light;
}

.ant-picker-today-btn {
    color: $secondary-color;

    &:hover {
        color: $secondary-color-light;
    }
}
