.counter-container {
    background-color: $light-gray-2;
    border-radius: 20px;
    overflow: hidden;

    .counter-icon-wrapper {
        &:hover {
            background-color: $secondary-color !important;
            color: $white;
            transition: all 0.1s ease-in;
        }
    }
}

.counter-lg {
    height: 40px;
}

.counter-sm {
    height: 28px;
}

.counter-meat {
    .counter-icon-wrapper {
        &:hover {
            background-color: $meat-color-dark !important;
        }
    }
}
