.app-btn {
    font-family: $farm-font-montserrat;
    font-weight: 600;
    height: auto;
    border-width: 2px;
}

.app-btn-lg {
    padding: 12px 15px;
    font-size: 1rem;
}

.app-btn-sm {
    padding: 7px 15px;
    font-size: 0.875rem;
}

.app-primary-btn {
    background-color: $secondary-color;
    color: $light-gray-3;
    border-color: $secondary-color;

    &:focus {
        outline: 4px solid rgba(232, 94, 13, 0.3) !important;
    }

    &:hover,
    &:focus {
        border-color: $secondary-color;
        box-shadow: 0px 10px 22px rgba(232, 94, 13, 0.25);
        background-color: $secondary-color;
        color: $light-gray-3;
    }

    &:active {
        border-color: $secondary-color-dark;
        background-color: $secondary-color-dark;
        color: $light-gray-3;
    }

    &:disabled {
        background-color: $secondary-color-light;
        border-color: $secondary-color-light;
        color: $light-gray-3;

        &:hover {
            background-color: $secondary-color-light;
            border-color: $secondary-color-light;
            color: $light-gray-3;
        }
    }
}

.app-primary-outlined-btn {
    background-color: transparent;
    border: 2px solid $secondary-color;
    color: $secondary-color;

    &:hover {
        filter: drop-shadow(0px 10px 22px rgba(232, 94, 13, 0.25));
    }

    &:focus {
        outline: 4px solid rgba(232, 94, 13, 0.3) !important;
    }

    &:hover,
    &:focus {
        background-color: transparent;
        border-color: $secondary-color;
        color: $secondary-color;
    }
}

.app-secondary-btn {
    background-color: rgba(244, 123, 52, 0.2);
    color: $secondary-color;
    border-color: transparent;

    &:hover,
    &:focus {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $light-gray-3;
    }
}

.app-primary-meat-btn {
    background-color: $meat-color-dark;
    border-color: $meat-color-dark;

    &:hover,
    &:focus {
        background-color: $meat-color-dark;

        border-color: $meat-color-dark;
    }

    &:disabled {
        background-color: $meat-color-light;
        border-color: $meat-color-light;

        &:hover {
            background-color: $meat-color-light;
            border-color: $meat-color-light;
        }
    }
}

.app-primary-outlined-meat-btn {
    background-color: transparent;
    border: 2px solid $meat-color-dark;
    color: $meat-color-dark;

    &:hover,
    &:focus {
        background-color: $meat-color-dark;
        opacity: 0.8;
        border-color: $meat-color-dark;
        color: $light-gray-3;
    }
}

.app-secondary-meat-btn {
    background-color: $meat-color-secondary;
    border-color: $meat-color-secondary;
    color: $light-gray-3;

    &:hover,
    &:focus {
        background-color: $meat-color-secondary;
        border-color: $meat-color-secondary;
    }

    &:disabled {
        background-color: $meat-color-secondary-light;
        border-color: $meat-color-secondary-light;

        &:hover {
            background-color: $meat-color-secondary-light;
            border-color: $meat-color-secondary-light;
        }
    }
}
