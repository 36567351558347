.customer-section {
    img {
        width: 100%;
        max-width: 112px;
        max-height: 90px;

        @include media-breakpoint-up(lg) {
            max-width: 160px;
        }
    }
}
