.cart-btn-container {
    border: none;
    background-color: transparent;
    border: 2px solid $light-gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .ant-spin {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-spin-dot-item {
        background-color: $secondary-color;
    }

    &:hover:not([disabled]) {
        border-color: transparent;
        background-color: $fg-orange;

        .ant-spin-dot-item {
            background-color: $white;
        }

        svg {
            border: none;
            path {
                stroke: $light-gray-2 !important;
            }
        }
    }

    svg {
        height: 18px;
        width: 18px;

        path {
            stroke: $light-gray;
        }
    }

    .cart-icon-dark {
        path {
            stroke: $gray-3 !important;
            stroke-width: 3px !important;
        }
    }
}

.cart-btn-sm {
    width: 32px;
    height: 32px;
}

.cart-btn-lg {
    width: 40px;
    height: 40px;
}

.cart-btn-meat {
    &:hover:not([disabled]) {
        background-color: $meat-color-dark !important;
    }

    .ant-spin-dot-item {
        background-color: $meat-color-dark;
    }
}
