.dariy-slider-section {
    .swiper {
        margin-bottom: -80px;
    }

    .swiper-slide {
        padding-bottom: 80px;
    }

    .custom-arrow-next {
        right: -10px;

        @media only screen and (min-width: 1280px) {
            right: -20px;
        }
    }

    .custom-arrow-prev {
        left: -10px;

        @media only screen and (min-width: 1280px) {
            left: -20px;
        }
    }

    .product-card-hover {
        &:hover {
            box-shadow: none;
        }
    }
}
