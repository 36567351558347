.newsletter-container {
    background-color: #1c1e1c;
    margin-top: 20px;

    .newsletter-container-inner {
        position: relative;
        overflow: hidden;

        .newsletter-content {
            position: relative;
            padding-top: 24px;
            padding-bottom: 24px;
            z-index: 2;

            h3 {
                @include font($farm-font-montserrat, 600, 12px);
                color: #f2f2f2;
            }
            h2 {
                margin-top: 12px;
                margin-bottom: 12px;
                @include font($farm-font-inter, 600, 18px);
                color: #f2f2f2;
            }
            p {
                max-width: 409px;
                @include font($farm-font-montserrat, 400, 12px);
                color: #f2f2f2;
            }

            .newsletter-input-container {
                position: relative;
                max-width: 550px;
                width: 100%;
                height: 44px;

                .input-icon {
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-50%);
                }

                input {
                    height: 100%;
                    border-radius: 8px;
                    border: none;
                    padding: 0 140px 0 45px;
                    width: 100%;
                    @include font($farm-font-roboto, 400, 14px);

                    &::placeholder {
                        @include font($farm-font-roboto, 400, 14px);
                    }

                    &:focus {
                        outline: none;
                    }
                }
                button {
                    position: absolute;
                    right: 4px;
                    margin: 0 2px;
                    top: 4px;
                    bottom: 4px;
                    @include font($farm-font-montserrat, 500, 14px);
                    color: #f2f2f2;
                    background-color: $fg-orange;
                    border: none;
                    border-radius: 8px;
                    width: 120px;
                    text-align: center;

                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }

                    &:hover {
                        background-color: $secondary-color-dark;
                    }
                }

                button:disabled,
                button[disabled] {
                    opacity: 0.7;
                }

                .news-error-label {
                    left: 45px;
                    bottom: 0;
                }
            }

            @include media-breakpoint-up(md) {
                padding-top: 48px;
                padding-bottom: 48px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 100px;
                padding-bottom: 100px;

                h3 {
                    @include font($farm-font-montserrat, 500, 16px);
                }

                h2 {
                    @include font($farm-font-inter, 500, 32px);
                }

                p {
                    @include font($farm-font-montserrat, 400, 14px);
                }

                .newsletter-input-container {
                    height: 55px;
                }
            }
        }

        img {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
}
